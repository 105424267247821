import "intro.js/introjs.css";
import "./App.css";
import { Chalkboard } from "./components/Chalkboard";
import { ResultsProvider } from "./components/ResultsProvider";

function App() {
  return (
    <ResultsProvider>
      <Chalkboard />
    </ResultsProvider>
  );
}

export default App;
