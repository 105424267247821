import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  Box,
  Button,
  IconButton,
  Grid,
  GridItem,
  Center,
  HStack,
  ModalFooter,
  useToast,
  ButtonProps,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useMultiplier, useResults } from "./ResultsProvider";
import { FiDelete } from "react-icons/fi";

export type ScoreInputProps = Omit<ModalProps, "children"> & {
  player: string;
  transform?: string;
};

function DigitButton({
  width,
  height,
  digit,
  func,
  fontSize,
}: {
  width?: ButtonProps["width"];
  height?: ButtonProps["height"];
  fontSize?: ButtonProps["fontSize"];
  digit: number;
  func: (digit: number) => void;
}) {
  return (
    <Button
      paddingTop="2px"
      width={width}
      height={height}
      backgroundColor="black"
      borderColor="brand.400"
      borderWidth="3px"
      borderRadius="6px"
      fontSize={fontSize}
      aria-label={`${digit}`}
      onClick={() => func(digit)}
    >
      {digit}
    </Button>
  );
}

export function ScoreInput({
  player,
  transform,
  isOpen,
  onClose,
  ...rest
}: ScoreInputProps) {
  const [results, setResults] = useResults();
  const [multiplier] = useMultiplier();
  const [value, setValue] = useState(0);
  const toast = useToast();

  const clearAndClose = useCallback(() => {
    setValue(0);
    onClose();
  }, [onClose]);

  const opponent = useCallback(() => {
    return value > 157 ? 0 : 157 - value;
  }, [value]);

  const addDigit = useCallback(
    (digit: number) => {
      if (value < 100) {
        setValue(value * 10 + digit);
      }
    },
    [value]
  );

  const backspace = useCallback(() => {
    setValue(Math.floor(value / 10));
  }, [value]);

  const recordValue = useCallback(() => {
    toast({
      title: "Punkte geschrieben",
      description: `${
        multiplier ?? 1
      } mal ${value} bzw. ${opponent()} Punkte. Total: ${
        (multiplier ?? 1) * value
      } und ${(multiplier ?? 1) * opponent()}`,
      status: "success",
    });
    setResults?.(
      (results ?? []).concat([
        [
          multiplier ?? 1,
          player === "A" ? value : opponent(),
          player === "A" ? opponent() : value,
          true,
        ],
      ])
    );
    clearAndClose();
  }, [
    clearAndClose,
    multiplier,
    opponent,
    player,
    results,
    setResults,
    value,
    toast,
  ]);

  const listenForNumberInput = useCallback(
    (e: KeyboardEvent) => {
      if (e.metaKey || e.ctrlKey || e.altKey) {
        return;
      }

      if ("0123456789".includes(e.key)) {
        addDigit(parseInt(e.key));
      } else if (e.key === "Backspace") {
        backspace();
      } else if (e.key === "Enter") {
        if (value === 0) {
          return;
        }
        recordValue();
      } else {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
    },
    [addDigit, backspace, recordValue, value]
  );

  useEffect(() => {
    if (isOpen) {
      globalThis?.addEventListener("keydown", listenForNumberInput);
    }
    return () => {
      if (isOpen) {
        globalThis?.removeEventListener("keydown", listenForNumberInput);
      }
    };
  }, [isOpen, listenForNumberInput, value]);

  return (
    <Modal
      isCentered={true}
      isOpen={isOpen}
      onClose={clearAndClose}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <Box transform={transform}>
          <ModalHeader>
            <HStack>
              <Text
                paddingTop={{ base: 0, sm: "1.1rem" }}
                fontSize={{ base: "xl", sm: "3xl" }}
                color="brand.400"
              >
                Resultat{" "}
              </Text>
              <Box
                fontSize={{ base: "lg", sm: "2xl" }}
                color="brand.400"
                width="1.5em"
                height="1.5em"
                borderRadius="50%"
                border="1px solid"
                borderColor="brand.400"
                textAlign="center"
              >
                {multiplier}x
              </Box>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody paddingBottom={{ base: 0, sm: 2 }}>
            <Center>
              <Grid
                gridGap={{ base: "0.25rem", sm: "0.25em" }}
                templateColumns="auto auto auto"
              >
                <GridItem colSpan={3} alignContent="right" alignItems="right">
                  <Text
                    paddingTop={2}
                    paddingRight={2}
                    paddingBottom={0}
                    marginBottom={{ base: 1, sm: 2 }}
                    textAlign="right"
                    fontSize={{ base: "2xl", sm: "5xl" }}
                    backgroundColor="gray.300"
                  >
                    {value}
                  </Text>
                </GridItem>
                <GridItem colSpan={2}>
                  <Text
                    textAlign="left"
                    size="sm"
                    paddingBottom={{ base: 0, sm: 2 }}
                  >
                    Gegner:
                  </Text>
                </GridItem>
                <GridItem>
                  <Text
                    textAlign="right"
                    size="sm"
                    paddingBottom={{ base: 0, sm: 2 }}
                  >
                    {opponent()}
                  </Text>
                </GridItem>

                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
                  <GridItem key={`gridDigit${i}`}>
                    <DigitButton
                      digit={i}
                      fontSize={{ base: "1.5rem", sm: "2rem" }}
                      width={{ base: "3.5rem", sm: "4rem" }}
                      height={{ base: "2.5rem", sm: "4rem" }}
                      func={addDigit}
                    />
                  </GridItem>
                ))}
                <GridItem>
                  <DigitButton
                    digit={257}
                    fontSize={{ base: "1.5rem", sm: "2rem" }}
                    width={{ base: "3.5rem", sm: "4rem" }}
                    height={{ base: "2.5rem", sm: "4rem" }}
                    func={setValue}
                  />
                </GridItem>
                <GridItem>
                  <DigitButton
                    digit={0}
                    fontSize={{ base: "1.5rem", sm: "2rem" }}
                    width={{ base: "3.5rem", sm: "4rem" }}
                    height={{ base: "2.5rem", sm: "4rem" }}
                    func={addDigit}
                  />
                </GridItem>
                <GridItem>
                  <IconButton
                    fontSize={{ base: "1.5rem", sm: "2rem" }}
                    width={{ base: "3.5rem", sm: "4rem" }}
                    height={{ base: "2.5rem", sm: "4rem" }}
                    backgroundColor="black"
                    borderColor="brand.400"
                    borderWidth="3px"
                    borderRadius="6px"
                    aria-label="Löschen"
                    onClick={backspace}
                    icon={<FiDelete />}
                  />
                </GridItem>
              </Grid>
            </Center>
          </ModalBody>
          <ModalFooter>
            <HStack height={{ base: "2.5rem", sm: "4rem" }}>
              <Button
                paddingTop={{ base: "1px", sm: "4px" }}
                backgroundColor="gray.600"
                color="white"
                height="100%"
                fontSize={{ base: "1.2rem", sm: "2rem" }}
                onClick={clearAndClose}
                _hover={{ backgroundColor: "gray.500" }}
              >
                Abbruch
              </Button>
              <Button
                paddingTop={{ base: "1px", sm: "4px" }}
                backgroundColor="green.600"
                color="white"
                height="100%"
                fontSize={{ base: "1.2rem", sm: "2rem" }}
                type="submit"
                _hover={{ backgroundColor: "green.500" }}
                isDisabled={value === 0}
                onClick={recordValue}
              >
                Schreiben
              </Button>
            </HStack>
          </ModalFooter>
        </Box>
      </ModalContent>
    </Modal>
  );
}
