import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  Box,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { useResults, useUndo } from "./ResultsProvider";
import { FaTrashAlt } from "react-icons/fa";
import { MdAutorenew, MdRestoreFromTrash, MdUndo } from "react-icons/md";

export type ScoreModalProps = Omit<ModalProps, "children"> & {
  player: string;
  transform?: string;
};

function select(player: string, a: number, b: number) {
  const retval = player === "A" ? a : b;
  if (retval === 0) {
    return "";
  } else {
    return retval;
  }
}

/**
 * Hack to re-enable the scroll bar at least for upright lists
 * - Transforming the <ModalContent> does not work, as it forces `style="…;transform:none"` (unknown, where that is inserted)
 * - Adding a <Box> prevents the scrollbar from appearing
 * @param props `transform` and `children` props
 * @returns either the children (if no transform necessary) or them wrapped in a transformation <Box>
 */
function MaybeTransform({
  transform,
  children,
}: {
  transform?: string;
  children: ReactNode;
}) {
  if (transform) {
    return (
      <Box transform={transform} overflowY="scroll">
        {children}
      </Box>
    );
  } else {
    return <>{children}</>;
  }
}

export function ScoreModal({
  player,
  transform,
  isOpen,
  onClose,
  ...rest
}: ScoreModalProps) {
  const [results, setResults] = useResults();
  const [undo, setUndo] = useUndo();
  const toast = useToast();

  function deleteResult(which: number) {
    setResults?.(
      results!.map((x, i) => (i === which ? [x[0], x[1], x[2], !x[3]] : x))
    );
  }

  const sum = results?.reduce(
    (sum, curr) => {
      const factor = curr[0] * (curr[3] ? 1 : 0);
      return [0, sum[1] + factor * curr[1], sum[2] + factor * curr[2]];
    },
    [0, 0, 0]
  ) ?? [0, 0, 0];
  return (
    <Modal
      size="lg"
      isCentered={true}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <MaybeTransform transform={transform}>
          <ModalHeader>Resultate</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table size="sm">
              <Thead fontSize={{ base: "sm", sm: "lg" }}>
                <Tr>
                  <Th isNumeric>Wir</Th>
                  <Th isNumeric>Gegner</Th>
                  <Th isNumeric>Faktor</Th>
                  <Th isNumeric>Löschen</Th>
                </Tr>
              </Thead>
              <Tbody>
                {results && results.length > 0 ? (
                  results.map(([x, a, b, valid], i) => (
                    <Tr
                      key={`row${i}`}
                      className={valid ? undefined : "strikeout"}
                      paddingY={1}
                    >
                      <Td
                        isNumeric
                        paddingY={1}
                        fontSize={{ base: "2xl", sm: "3xl" }}
                      >
                        {select(player, a, b)}
                      </Td>
                      <Td
                        isNumeric
                        paddingY={1}
                        fontSize={{ base: "2xl", sm: "3xl" }}
                      >
                        {select(player, b, a)}
                      </Td>
                      <Td
                        isNumeric
                        paddingY={1}
                        fontSize={{ base: "2xl", sm: "3xl" }}
                      >
                        {x}x
                      </Td>
                      <Td
                        isNumeric
                        paddingY={1}
                        fontSize={{ base: "2xl", sm: "3xl" }}
                      >
                        <IconButton
                          key={`trash${i}`}
                          backgroundColor={valid ? "red.500" : "gray.600"}
                          color="white"
                          _hover={{
                            backgroundColor: "red.600",
                            color: valid ? "red.200" : "gray.300",
                            transform: "scale(1.2,1.2)",
                          }}
                          aria-label="Löschen"
                          icon={valid ? <FaTrashAlt /> : <MdRestoreFromTrash />}
                          onClick={() => deleteResult(i)}
                        />
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={3} fontSize={{ base: "xl", sm: "2xl" }}>
                      (Keine)
                    </Td>
                  </Tr>
                )}
              </Tbody>
              <Tfoot fontSize={{ base: "lg", sm: "xl" }}>
                <Tr>
                  <Th
                    isNumeric
                    paddingTop={4}
                    fontSize={{ base: "xl", sm: "3xl" }}
                  >
                    {select(player, sum[1], sum[2])}
                  </Th>
                  <Th
                    isNumeric
                    paddingTop={4}
                    fontSize={{ base: "xl", sm: "3xl" }}
                  >
                    {select(player, sum[2], sum[1])}
                  </Th>
                  <Th></Th>
                  <Th isNumeric fontSize={{ base: "xl", sm: "3xl" }}>
                    {
                      /*
                       * - If there is a list, we can start a new game (saving to undo buffer)
                       * - Otherwise, if there is an undo buffer, allow undo
                       * - Else, show nothing
                       */
                      (results?.length ?? 0) === 0 ? (
                        (undo?.length ?? 0) === 0 ? null : (
                          <IconButton
                            backgroundColor="blue.500"
                            color="white"
                            _hover={{
                              backgroundColor: "blue.600",
                              color: "blue.200",
                              transform: "scale(1.2,1.2)",
                            }}
                            aria-label="Neu"
                            icon={<MdUndo />}
                            onClick={() => {
                              toast({
                                title: "Spielstand zurückgeholt",
                                description: "Zum Glück gibt es Undo!",
                                status: "info",
                              });
                              setResults!(undo ?? []);
                              onClose();
                            }}
                          />
                        )
                      ) : (
                        <IconButton
                          backgroundColor="green.500"
                          color="white"
                          _hover={{
                            backgroundColor: "green.600",
                            color: "green.200",
                            transform: "scale(1.2,1.2)",
                          }}
                          aria-label="Neu"
                          icon={<MdAutorenew />}
                          onClick={() => {
                            toast({
                              title: "Neues Spiel gestartet",
                              description:
                                "Zurückholen des vorherigen Spielstandes mittels Undo in der Listenansicht oder hier rechts unten",
                              status: "info",
                            });
                            setUndo!(results ?? []);
                            setResults!([]);
                            onClose();
                          }}
                        />
                      )
                    }
                  </Th>
                </Tr>
              </Tfoot>
            </Table>
          </ModalBody>
        </MaybeTransform>
      </ModalContent>
    </Modal>
  );
}
