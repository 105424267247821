import { ButtonProps, Flex, StackProps } from "@chakra-ui/react";
import React from "react";

export type ButtonGroupProps = StackProps;

function doubleLength(borderWidth: ButtonGroupProps["borderWidth"]) {
  if (typeof borderWidth === "number") {
    return 2 * borderWidth;
  } else if (typeof borderWidth === "string") {
    const matches = borderWidth.match(/^([0-9.]+)(.*)/);
    if (matches) {
      return 2 * parseFloat(matches[1]) + matches[2];
    } else {
      console.error(`Cannot double string length ${borderWidth}`);
    }
  } else {
    console.error(`Cannot double unknown length ${borderWidth}`);
  }
}

/**
 * Render a group of buttons such that they look grouped:
 *
 *  /---+---+---\
 *  | A | B | C |
 *  \---+---+---/
 *
 * @param props A set of properties mostly passed through to the Stack, with the following exceptions:
 * - `children`: An Array of children
 * - `borderWidth` is also used as the children's border radius and the `padding` and `spacing` of the ButtonGroup
 * - `backgroundColor` is used to simulate the border between the children and is therefore overridden by `borderColor`
 * @returns The Stack grouping the objects
 */
export function ButtonGroup({
  direction,
  children,
  borderWidth,
  borderColor,
  ...rest
}: ButtonGroupProps) {
  let startProps: ButtonProps, middleProps: ButtonProps, endProps: ButtonProps;
  if (direction === "column") {
    startProps = {
      roundedBottom: "none",
      borderRadius: borderWidth,
      marginBottom: borderWidth,
    };
    middleProps = {
      rounded: "none",
      borderRadius: borderWidth,
      marginBottom: borderWidth,
    };
    endProps = {
      roundedTop: "none",
      borderRadius: borderWidth,
    };
  } else {
    // row
    startProps = {
      roundedRight: "none",
      borderRadius: borderWidth,
      marginRight: borderWidth,
    };
    middleProps = {
      rounded: "none",
      borderRadius: borderWidth,
      marginRight: borderWidth,
    };
    endProps = {
      roundedLeft: "none",
      borderRadius: borderWidth,
    };
  }
  if (Array.isArray(children)) {
    if (children.length <= 1) {
      return (
        <Flex
          direction={direction}
          borderColor={borderColor}
          backgroundColor={borderColor}
          spacing={borderWidth}
          padding={borderWidth}
          borderRadius={doubleLength(borderWidth)}
          {...rest}
        >
          {children}
        </Flex>
      );
    } else {
      return (
        <>
          <Flex
            flex="1 1 1"
            direction={direction}
            borderColor={borderColor}
            backgroundColor={borderColor}
            spacing={borderWidth}
            padding={borderWidth}
            borderRadius={doubleLength(borderWidth)}
            {...rest}
          >
            {React.Children.map(children, (child, index) => {
              if (index === 0) {
                return React.cloneElement(child, startProps);
              } else if (index === children.length - 1) {
                return React.cloneElement(child, endProps);
              } else {
                return React.cloneElement(child, middleProps);
              }
            })}
          </Flex>
        </>
      );
    }
  } else {
    return (
      <Flex
        direction={direction}
        borderColor={borderColor}
        backgroundColor={borderColor}
        spacing={borderWidth}
        padding={borderWidth}
        borderRadius={doubleLength(borderWidth)}
        {...rest}
      >
        {children}
      </Flex>
    );
  }
}
