import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ChalkState } from "../util/ChalkState";
import { useResults } from "./ResultsProvider";

type Props = {
  player: string;
  boxRef: RefObject<HTMLDivElement>;
};

function getComputedSize(elt: Element | null, property: string) {
  if (elt) {
    return parseFloat(
      getComputedStyle(elt).getPropertyValue(property).slice(0, -2)
    );
  } else {
    return 0;
  }
}

export function ChalkZ({ player, boxRef }: Props) {
  const [results] = useResults();
  const pathRef = useRef<SVGPathElement>(null);
  const [dimensions, setDimensions] = useState({
    height: getComputedSize(boxRef.current, "height"),
    width: getComputedSize(boxRef.current, "width"),
  });
  const cs = new ChalkState(player, results);

  function newPath(width: number, height: number) {
    const lx = Math.floor(0.05 * width),
      rx = Math.floor(0.95 * width),
      ty = Math.floor(0.15 * height),
      by = Math.floor(0.85 * height);
    const zPath = `M ${lx},${ty} ${rx},${ty} ${lx},${by} ${rx},${by}`;
    const hPath = cs.by5path(
      cs.v,
      cs.h,
      0,
      0.08 * width,
      rx,
      0.19 * height,
      0.11 * height
    );
    const fPath = cs.by2path(
      cs.f,
      0.2 * width,
      rx,
      0.54 * height,
      0.46 * height
    );
    const tPath = cs.by5path(
      0,
      cs.t,
      1,
      0.08 * width,
      rx,
      0.89 * height,
      0.81 * height
    );
    const digPath = cs.o
      ? cs.strokeText(
          `${cs.o}`,
          0.8 * width,
          1 * width,
          0.73 * height,
          0.65 * height
        )
      : "";

    return [zPath, hPath + fPath + tPath + digPath];
  }
  const path = newPath(dimensions.width, dimensions.height);
  const handleResize = useCallback(() => {
    const d = {
      height: getComputedSize(boxRef.current, "height"),
      width: getComputedSize(boxRef.current, "width"),
    };
    setDimensions(d);
  }, [boxRef]);

  useEffect(() => {
    if (dimensions.width === 0 || dimensions.height === 0) {
      // Wait for real rendering
      setTimeout(() => {
        handleResize();
      });
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <svg className="z" width="100%" height="100%">
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          id="c"
          x="0"
          y="0"
          width="1.2"
          height="1.1"
        >
          <feTurbulence result="t" baseFrequency=".4" />
          <feDisplacementMap
            in2="t"
            scale="4"
            xChannelSelector="G"
            yChannelSelector="B"
            in="SourceGraphic"
            result="d1"
          />
          <feDisplacementMap
            in2="t"
            in="SourceGraphic"
            result="d2"
            xChannelSelector="R"
            scale="4"
          />
          <feComposite in2="d2" in="d1" />
        </filter>
      </defs>
      <path
        ref={pathRef}
        stroke="#be5100"
        strokeWidth="3px"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        d={path[0]}
      />
      <path
        filter="url(#c)"
        stroke="#eee"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        fill="none"
        d={path[1]}
      />
    </svg>
  );
}
