import {
  Button,
  Text,
  Grid,
  GridItem,
  Box,
  useDisclosure,
  keyframes,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { Step as OldStep, Steps } from "intro.js-react";
import { Step, Options } from "intro.js";
import { ButtonGroup } from "./ButtonGroup";
import { ChalkZ } from "./ChalkZ";
import { useResults, useMultiplier, useUndo } from "./ResultsProvider";
import { RiFileListLine, RiInformationFill } from "react-icons/ri";
import { ScoreModal } from "./ScoreModal";
import { MdAutorenew, MdUndo } from "react-icons/md";
import { ScoreInput } from "./ScoreInput";

type Props = {
  player: string;
  transform?: string;
};

const pulsate = keyframes`
0% { transform-origin: center; transform: translate(-50%,-50%) scale(1); }
100% { transform-origin: center; transform: translate(-50%,-50%) scale(2); color: #be5100; text-shadow: -2px 2px 2px white, 2px -2px 2px white, 2px 2px 2px white, -2px -2px 2px white; }
`;

const introSteps: Step[] = [
  {
    title: "Der Rechen<wb/>schiefer",
    intro:
      '<p>Mit dieser kostenlosen Jasstafel der <a href="https://wundernas.ch" target="_blank">Wundernas</a> wird das Schreiben beim Schieber zum Kinderspiel! Diese Tour führt Sie durch die Anwendung.</p><p class="soft">Übrigens: Ausserhalb dieser Tour ist die Anwendung von beiden Seiten her gleichberechtigt bedienbar.</p>',
  },
  {
    element: "#multipliers",
    title: "Multiplikator",
    intro:
      "Hier wählen Sie den Multiplikator für die aktuelle Runde. Alle geschriebenen Punkte werden automatisch hiermit multipliziert.",
  },
  {
    element: "#weisB",
    title: "Weis",
    intro:
      "Mit diesen Tasten können Sie schnell Punkte nur einer Seite gutschreiben, beispielsweise bei einem Weis.",
  },
  {
    element: "#plusB",
    title: "Resultat",
    intro:
      '<p>Hier schreiben Sie die Punkte am Schluss der Runde gut. Die Differenz auf 157 wird automatisch dem Gegner gutgeschrieben.</p><p class="soft">Falls Sie mehr als 157 Punkte schreiben, z.B. einen Match (257), wird dem Gegner einfach nichts gutgeschrieben.</p>',
  },
  {
    element: "#listB",
    title: "Resultateliste",
    intro:
      "<p>Über jeden einzelnen Punkt wird akribisch Buch geführt. Hier können Sie diese Liste ansehen und falsche Eingaben jederzeit löschen (mit Undo).</p>",
  },
  {
    element: "#introB",
    title: "Informationen",
    intro:
      "<p>Mit diesem Knopf können Sie diese Tour jederzeit wiederholen.</p>",
  },
  {
    element: "#resetB",
    title: "Neues Spiel",
    intro:
      "<p>Starten Sie hiermit ein neues Spiel.</p><p>Falls Sie ihn aus Versehen gedrückt haben: Er verwandelt sich in einen <b>Undo</b>-Knopf.</p>",
  },
  {
    title: "Datenschutz",
    intro:
      "<p>Es werden keine Daten zu Ihrer Nutzung der Anwendung gesammelt. Alle Resultate werden nur auf Ihrem Gerät gespeichert und alle Aktionen auch nur da ausgeführt.</p><p>So sollte es doch immer sein, nicht?</p>",
  },
  {
    title: "Autor",
    intro:
      '<p>Die Software wurde geschrieben von <a href="https://netfuture.ch" target="_blank">Marcel Waldvogel</a> im Auftrag der <a href="https://wundernas.ch" target="_blank">Wundernas</a> und wird bereitgestellt ab Servern der <a href="https://trifence.ch" target="_blank">Trifence AG</a>.</p><p>Und nun: Viel Spass beim Jassen!</p>',
  },
];
const introOptions: Options = {
  nextLabel: "Weiter",
  prevLabel: "Zurück",
  doneLabel: "Ende", // Not shown?
  hideNext: true,
  hidePrev: true,
  overlayOpacity: 0.5, // Does not work?
};

export function BoardHalf({ player, ...rest }: Props) {
  const [intro, setIntro] = useState(!localStorage.getItem("JassIntroPassed"));
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isInputOpen,
    onOpen: onInputOpen,
    onClose: onInputClose,
  } = useDisclosure();
  const boxRef = useRef<HTMLDivElement>(null);
  const [results, setResults] = useResults();
  const [undo, setUndo] = useUndo();
  const [multiplier] = useMultiplier();
  const variant = useBreakpointValue({ base: "verticalSmall", md: "vertical" });
  const padding = useBreakpointValue({ base: 4, md: 6 });
  const showOptional = useBreakpointValue({ base: false, sm: true });
  const toast = useToast();

  function addToPlayer(player: string, value: number) {
    toast({
      title: "Weis geschrieben",
      description: `${multiplier ?? 1} mal ${value} Punkte. Total: ${
        (multiplier ?? 1) * value
      }`,
      status: "success",
    });
    setResults!(
      results?.concat([
        [
          multiplier ?? 1,
          player === "A" ? value : 0,
          player === "A" ? 0 : value,
          true,
        ],
      ]) ?? []
    );
  }
  const sum = results?.reduce(
    (sum, curr) => [
      0,
      sum[1] + (curr[3] ? curr[0] : 0) * curr[1],
      sum[2] + (curr[3] ? curr[0] : 0) * curr[2],
    ],
    [0, 0, 0]
  ) ?? [0, 0, 0];
  const mySum = sum[player === "A" ? 1 : 2];

  return (
    <Grid
      height="full"
      width="full"
      alignItems="center"
      templateColumns="2.5em 1fr 2.5em"
      gridGap="2"
      {...rest}
    >
      <Steps
        enabled={intro}
        steps={introSteps as OldStep[]}
        initialStep={0}
        onExit={() => {
          setIntro(false);
          localStorage.setItem("JassIntroPassed", "1");
        }}
        options={introOptions}
      />
      <GridItem>
        <ButtonGroup
          width={{ base: "4rem", sm: "5rem" }}
          id={"weis" + player}
          direction="column"
          borderColor="#be5100"
          borderWidth="3px"
        >
          {[20, -40, 50, -70, 100, -150, -200]
            .filter((value) => showOptional || value > 0)
            .map((value) => (
              <Button
                variant={variant}
                key={Math.abs(value)}
                fontWeight={value < 0 ? 300 : undefined}
                onClick={(ev) => addToPlayer(player, Math.abs(value))}
              >
                {Math.abs(value)}
              </Button>
            ))}
        </ButtonGroup>
      </GridItem>
      <GridItem height="full">
        <Box ref={boxRef} position="relative" width="100%" height="100%">
          <Box
            zIndex={2}
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%,-50%)"
            width="100%"
            height="100%"
          >
            <Text
              padding="0"
              position="absolute"
              top="35%"
              left="50%"
              transform="translate(-50%,-50%)"
              fontSize={{ base: "5xl", sm: "7xl" }}
              color="white"
              animation={
                mySum >= 2500
                  ? `1.5s ease 0s 3 alternate ${pulsate}, 10000s ease 4.5s 1 reverse ${pulsate}`
                  : ""
              }
            >
              {("   " + mySum).slice(-4)}
            </Text>
          </Box>
          <ChalkZ player={player} boxRef={boxRef} />
        </Box>
      </GridItem>
      <GridItem>
        <ButtonGroup
          width={{ base: "4rem", sm: "5rem" }}
          direction="column"
          borderColor="brand.400"
          borderWidth="3px"
          marginRight={2}
        >
          <Button
            id={"plus" + player}
            variant={variant}
            paddingY={padding}
            onClick={onInputOpen}
            height={{ base: "1.2em", sm: "1.5em" }}
          >
            <ScoreInput
              isOpen={isInputOpen}
              onClose={onInputClose}
              player={player}
              transform={rest.transform}
            />
            +
          </Button>
          <Button
            id={"list" + player}
            variant={variant}
            paddingTop={padding ?? 0}
            onClick={onOpen}
            height={{ base: "1.2em", sm: "1.5em" }}
          >
            <ScoreModal
              isOpen={isOpen}
              onClose={onClose}
              player={player}
              transform={rest.transform}
            />
            <RiFileListLine />
          </Button>
          <Button
            id={"intro" + player}
            variant={variant}
            onClick={() => {
              setIntro(true);
            }}
            height={{ base: "1.2em", sm: "1.5em" }}
          >
            <RiInformationFill />
          </Button>
          {
            /*
             * - On empty list but non-empty undo buffer, display Undo button
             * - Else, display Renew button; disabled if list is empty
             */
            (results?.length ?? 0) === 0 && undo?.length !== 0 ? (
              <Button
                zIndex={2}
                id={"reset" + player}
                variant={variant}
                paddingTop={padding}
                onClick={() => {
                  toast({
                    title: "Spielstand zurückgeholt",
                    description: "Zum Glück gibt es Undo!",
                    status: "info",
                  });
                  setResults?.(undo ?? []);
                }}
                height={{ base: "1.2em", sm: "1.5em" }}
              >
                <MdUndo />
              </Button>
            ) : (
              <Button
                zIndex={2}
                id={"reset" + player}
                isDisabled={results?.length === 0}
                variant={variant}
                paddingTop={padding}
                onClick={() => {
                  toast({
                    title: "Neues Spiel gestartet",
                    description:
                      "Zurückholen des vorherigen Spielstandes mittels Undo an derselben Stelle",
                    status: "info",
                  });
                  setUndo!(results ?? []);
                  setResults!([]);
                }}
                height={{ base: "1.2em", sm: "1.5em" }}
              >
                <MdAutorenew />
              </Button>
            )
          }
        </ButtonGroup>
      </GridItem>
    </Grid>
  );
}
