import { JassResults } from "../components/ResultsProvider";

export class ChalkState {
  v: number = 0; // five-hundreds (from matches)
  h: number = 0; // hundreds
  f: number = 0; // fifties
  t: number = 0; // twenties
  o: number = 0; // remainder (-19 .. +19)
  player?: string;

  constructor(player?: string, results?: JassResults) {
    this.player = player;
    if (player && results) {
      for (const r of results) {
        if (r[3]) {
          this.add(r[0] * r[player === "A" ? 1 : 2]);
        }
      }
    }
  }

  value() {
    return this.v * 500 + this.h * 100 + this.f * 50 + this.t * 20 + this.o;
  }

  playerModifier() {
    return this.player === "A" ? 1 : 0;
  }

  by5s(n: number): string {
    let res = "";
    while (n >= 5) {
      res += "||||/ ";
      n -= 5;
    }
    return res + "|".repeat(n);
  }
  by2s(n: number): string {
    return "X".repeat(Math.floor(n / 2)) + "/".repeat(n % 2);
  }
  renderText() {
    console.log("V".repeat(this.v) + this.by5s(this.h), "\t\t\t", this.o);
    console.log(this.by2s(this.f));
    console.log(this.by5s(this.t), "\t\t\t", this.value());
  }

  threeDots(
    xl: number,
    xr: number,
    yb: number,
    yt: number,
    dx: number
  ): string {
    let res = "";
    for (const i of [0, 1, 2]) {
      res += ` M ${xl + i * dx},${yb} l ${0.1 * dx},${-0.1 * dx} `;
    }
    return res;
  }
  by5path(
    v: number,
    h: number,
    modifier: number,
    xl: number,
    xr: number,
    yb: number,
    yt: number
  ): string {
    let res = "";
    const dy = yb - yt;
    const dx = 0.25 * dy;
    const maxX = xr - 6 * dx;
    for (let i = 0; i < v; i++) {
      const jitter1 = 0.15 * ((i + modifier) % 3);
      const jitter2 = 0.05 * ((i + this.playerModifier()) % 5);
      if (xl > maxX) {
        return res + this.threeDots(xl, xr, yb, yt, dx);
      }
      res += ` M ${xl - jitter2 * dx},${yt + (jitter1 - jitter2) * 0.2 * dy} ${
        xl + (0.8 + jitter1) * dx
      },${yb - 0.2 * dx} ${xl + 1.8 * dx},${yt} M ${xl},${yb + 0.2 * dx} l ${
        (1.6 + jitter2) * dx
      },${(jitter1 - jitter2) * 0.2 * dy}`;
      xl += 3 * dx;
    }
    for (let i = 0; i < h; i++) {
      const jitter1 = 0.15 * ((i + modifier + this.playerModifier()) % 3);
      const jitter2 = 0.05 * ((i + modifier) % 5);
      if (i % 5 === 4) {
        res += ` M ${xl - 4.5 * dx},${yb - 0.1 * dy} ${xl + 0.5 * dx},${
          yb - 0.9 * dy
        } `;
        xl += 0.8 * dx;
        if (xl > maxX && i < h) {
          return res + this.threeDots(xl, xr, yb, yt, dx);
        }
      } else {
        res += ` M ${xl + jitter1 * dx},${
          yb + (jitter1 - jitter2) * 0.2 * dy
        } ${xl + (1 - jitter2) * dx},${yt + (jitter2 + jitter1) * 0.1 * dy} `;
        xl += dx;
      }
    }
    return res;
  }
  by2path(h: number, xl: number, xr: number, yb: number, yt: number): string {
    let res = "";
    const dy = yb - yt;
    const dx = 0.25 * dy;
    const maxX = xr - 5 * dx;
    for (let i = 0; i < h; i++) {
      const jitter1 = 0.15 * ((i + this.playerModifier()) % 3);
      const jitter2 = 0.05 * (i % 5);
      if (i % 2 === 1) {
        res += ` M ${xl + (1.2 + jitter1) * dx},${yb + jitter2 * 0.3 * dy} ${
          xl + (0.3 + jitter1 - jitter2) * dx
        },${yt + (jitter1 + jitter2) * 0.2 * dy} `;
        xl += 2.3 * dx;
        if (xl > maxX && i < h) {
          return res + this.threeDots(xl, xr, yb, yt, dx);
        }
      } else {
        res += ` M ${xl - jitter1 * dx},${yb + jitter1 * 0.2 * dy} ${
          xl + 1.5 * dx
        },${yt - (jitter1 + jitter2) * 0.1 * dy} `;
      }
    }
    return res;
  }

  scale(
    path: Array<string | number>,
    xl: number,
    xr: number,
    yb: number,
    yt: number,
    dx: number,
    dy: number
  ) {
    let str = "";
    let relative = false;
    let isX = true;
    for (const e of path) {
      if (typeof e === "string") {
        str += e;
        relative = e.toLowerCase() === e;
        isX = !"vV".includes(e);
      } else {
        if (isX) {
          str += `${Math.round((relative ? 0 : xl) + (e * dx) / 15.0)} `;
        } else {
          str += `${Math.round((relative ? 0 : yt) + (e * dy) / 15.0)} `;
        }
        isX = !isX;
      }
    }
    return str;
  }
  strokeChar(
    char: string,
    xl: number,
    xr: number,
    yb: number,
    yt: number
  ): string {
    const dy = yb - yt;
    const dx = 0.8 * dy;
    const paths: { [key: string]: Array<string | number> } = {
      "9": [
        "M",
        9,
        2,
        "C",
        6,
        0,
        0,
        1,
        1,
        5,
        "c",
        2,
        9,
        9,
        1,
        8,
        -3,
        -1,
        -1,
        0,
        15,
        -7,
        13,
      ],
      "8": [
        "M",
        4,
        1,
        "c",
        5,
        1,
        4,
        6,
        1,
        6,
        -5,
        -1,
        -5,
        7,
        -1,
        7,
        5,
        1,
        5,
        -6,
        1,
        -7,
        -6,
        -1,
        -3,
        -7,
        1,
        -6,
      ],
      "7": ["M", 7, 8, 1, 7, "m", 2, 8, "C", 4, 6, 8, 1, 8, 1, "H", 1],
      "6": [
        "M",
        1,
        8,
        "c",
        3,
        -3,
        8,
        0,
        7,
        2,
        -1,
        7,
        -8,
        3,
        -7,
        0,
        "s",
        4,
        -8,
        6,
        -9,
      ],
      "5": [
        "M",
        1,
        10,
        "c",
        4,
        8,
        9,
        2,
        8,
        0,
        -1,
        -6,
        -7,
        -3,
        -7,
        -3,
        "V",
        1,
        "h",
        6,
      ],
      "4": ["M", 10, 11, "H", 1, "l", 5, -8, "m", 1, 11, "L", 8, 1],
      "3": ["M", 1, 4, "c", 4, -8, 11, 1, 4, 3, 8, 2, -1, 13, -4, 3],
      "2": ["M", 1, 5, "c", 4, -8, 8, -2, 7, 0, "l", -7, 9, "h", 7],
      "1": ["M", 6, 14, "C", 8, 6, 7, 1, 7, 1, "L", 1, 5],
      "0": ["M", 5, 2, "c", 5, 0, 4, 12, 0, 12, "C", 0, 15, -1, 2, 5, 1],
      "-": ["M", 4, 10, "L", 10, 9],
    };
    return this.scale(paths[char] ?? [], xl, xr, yb, yt, dx, dy);
  }
  strokeText(
    text: string,
    xl: number,
    xr: number,
    yb: number,
    yt: number
  ): string {
    const dy = yb - yt;
    const dx = 0.6 * dy;
    let res = "";
    xl = xr - dx;
    for (const c of text.split("").reverse()) {
      xl -= dx;
      res += this.strokeChar(c, xl, xr, yb, yt);
    }
    return res;
  }
  /**
   * Make strokes and ajust ones accordingly
   * @param f Fifties strokes to make
   * @param t Twenties strokes to make
   */
  strokes(f: number, t: number) {
    this.f += f;
    this.t += t;
    this.o -= f * 50 + t * 20;
  }
  add(x: number) {
    // Add a 5 hundred, if possible
    if (x === 514 || x === 771) {
      this.v++;
      x -= 500;
    }
    // Add all hundreds
    while (x > 100) {
      this.h++;
      x -= 100;
    }
    if (this.o + x > 85) {
      this.h++;
      x -= 100;
    }
    // Minimize number of strokes
    this.o += x;
    if (this.o < 20) {
      // Can go down to -20, but this is all OK as is
      return;
    } else if (this.o <= 30) {
      this.strokes(0, 1);
    } else if (this.o <= 45) {
      this.strokes(0, 2);
    } else if (this.o <= 60) {
      this.strokes(1, 0);
    } else if (this.o < 80) {
      this.strokes(1, 1);
    } else {
      this.strokes(0, 4);
    }
  }
}

export function testChalk() {
  const cs = new ChalkState();
  [
    10, 10, 10, 10, 10, 20, 25, 25, 25, 25, 30, 30, 30, 35, 35, 35, 40, 40, 40,
    45, 45, 45, 50, 80, 514, 127, 256,
  ].forEach((x) => {
    console.log("----------------------------------------------", x);
    cs.add(x);
    cs.renderText();
  });
}
