import { GroupedRadios } from "./GroupedRadios";
import { Box, Center, HStack, IconButton } from "@chakra-ui/react";
import { useMultiplier } from "./ResultsProvider";
import { BsFullscreen, BsFullscreenExit } from "react-icons/bs";
import { useState } from "react";

export function BoardControls() {
  const [multiplier, setMultiplier] = useMultiplier();
  const [fullScreen, setFullScreen] = useState(!!document.fullscreenElement);
  function goFullScreen() {
    if (document.fullscreenElement) {
      setFullScreen(false);
      document.exitFullscreen();
    } else {
      var e = document.body;
      setFullScreen(true);
      if (typeof e?.requestFullscreen === "function") {
        e.requestFullscreen();
      } else if (typeof (e as any)?.webkitRequestFullscreen === "function") {
        (e as any).webkitRequestFullscreen();
      } else {
        console.error("Cannot go full screen");
      }
    }
  }

  return (
    <Box maxWidth="full">
      <Center width="full" maxWidth="full" position="relative">
        <Box
          position="absolute"
          top="50%"
          left="0"
          width="full"
          height="3px"
          backgroundColor="brand.400"
          transform="translateY(-50%)"
        ></Box>
        <HStack height={{ base: "3rem", sm: "3.5rem", md: "4rem" }}>
          <GroupedRadios
            id="multipliers"
            extraPadding={{ base: 2, md: 6, lg: 8 }}
            fontSize={{ base: "2xl", sm: "4xl" }}
            height="100%"
            options={[["1x"], ["2x"], ["3x"], ["4x", false], ["5x", false]]}
            direction="row"
            borderColor="brand.400"
            borderWidth="3px"
            zIndex={2}
            value={multiplier ?? 1}
            onChange={(e) => {
              setMultiplier?.(parseInt((e.target as any).value) ?? 1);
            }}
          />
          {document.fullscreenEnabled ||
          (document as any).webkitFullscreenEnabled ? (
            <>
              <Box width="0.5rem" />
              <IconButton
                width={{ base: "3rem", sm: "3.5rem", md: "4rem" }}
                height="100%"
                backgroundColor="black"
                borderColor="brand.400"
                borderWidth="3px"
                borderRadius="6px"
                padding={2}
                fontSize={{ base: "2xl", sm: "4xl" }}
                aria-label="Fullscreen"
                onClick={goFullScreen}
                icon={fullScreen ? <BsFullscreenExit /> : <BsFullscreen />}
              />
            </>
          ) : undefined}
        </HStack>
      </Center>
    </Box>
  );
}
