import { Center, Grid, GridItem } from "@chakra-ui/react";
import { BoardControls } from "./BoardControls";
import { BoardHalf } from "./BoardHalf";
import slate from "../slate.svg";

export function Chalkboard() {
  return (
    <Center padding="0" height="100vh" width="100vw" boxSizing="content-box">
      <Grid
        paddingX="2"
        height="100%"
        width="100%"
        maxWidth="70vh"
        templateRows="2rem 1fr auto 1fr 2rem"
        gridGap="0"
        backgroundImage={slate}
        backgroundSize="cover"
        borderRadius="2rem"
      >
        <GridItem />
        <GridItem>
          <BoardHalf key="A" player="A" transform="rotate(180deg)" />
        </GridItem>
        <GridItem>
          <BoardControls />
        </GridItem>
        <GridItem>
          <BoardHalf key="B" player="B" />
        </GridItem>
        <GridItem />
      </Grid>
    </Center>
  );
}
