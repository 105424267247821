import { createContext, ReactNode, useContext, useState } from "react";

export type JassResults = Array<[number, number, number, boolean]>;
export type JassResultsContext = {
  res: JassResults;
  setRes: (jr: JassResults) => void;
  mul: number;
  setMul: (m: number) => void;
  undo: JassResults;
  setUndo: (jr: JassResults) => void;
};
export type ResultsState = [JassResults, (jr: JassResults) => void];
export type MultiplierState = [number, (m: number) => void];
type ResultsType = "JassResults" | "JassUndo";

const emptyResults: Partial<JassResultsContext> = {};

export const ResultsContext = createContext(emptyResults);

function getStoredResults(key: ResultsType): JassResults {
  const jr = localStorage.getItem(key) ?? "[]";
  let parsedResults: any = [];
  try {
    parsedResults = JSON.parse(jr);
  } catch (error) {
    console.error("Ignoring badly JSON-formatted JassResults", jr);
  }
  let validatedResults: JassResults = [];
  if (Array.isArray(parsedResults)) {
    for (const i of parsedResults) {
      if (Array.isArray(i) && (i.length === 3 || i.length === 4)) {
        // Without helper variables, TS does not recognize the types matching
        const [a, b, c, d] = i;
        if (
          typeof a === "number" &&
          typeof b === "number" &&
          typeof c === "number" &&
          typeof (d ?? true) === "boolean"
        ) {
          validatedResults.push([a, b, c, d ?? true]);
        } else {
          console.error("Ignoring non-numeric^3+boolean result line", i);
        }
      } else {
        console.error("Ignoring non-[3/4] result line", i);
      }
    }
  } else {
    console.error("Ignoring non-array JassResults", parsedResults);
  }
  return validatedResults;
}

function setStoredResults(jr: JassResults, key: ResultsType) {
  localStorage.setItem(key, JSON.stringify(jr));
}

export function ResultsProvider({ children }: { children: ReactNode }) {
  const [undo, setUndo] = useState(getStoredResults("JassUndo"));
  const [results, setResults] = useState(getStoredResults("JassResults"));
  const [multiplier, setMultiplier] = useState(1);

  function setAndStoreResults(jr: JassResults) {
    setStoredResults(jr, "JassResults");
    setResults(jr);
  }

  return (
    <ResultsContext.Provider
      value={{
        res: results,
        setRes: setAndStoreResults,
        mul: multiplier,
        setMul: setMultiplier,
        undo,
        setUndo,
      }}
    >
      {children}
    </ResultsContext.Provider>
  );
}

export function useResults(): Partial<ResultsState> {
  const context = useContext(ResultsContext);
  if (context?.setRes) {
    return [context.res!, context.setRes!];
  } else {
    console.error("useResults must be used within a <ResultsProvider>");
    return [[], (jr) => {}];
  }
}

export function useMultiplier(): Partial<MultiplierState> {
  const context = useContext(ResultsContext);
  if (context?.setMul) {
    return [context.mul!, context.setMul!];
  } else {
    console.error("useMultiplier must be used within a <ResultsProvider>");
    return [1, (mul) => {}];
  }
}

export function useUndo(): Partial<ResultsState> {
  const context = useContext(ResultsContext);
  if (context?.setUndo) {
    return [context.undo!, context.setUndo!];
  } else {
    console.error("useUndo must be used within a <ResultsProvider>");
    return [[], (jr) => {}];
  }
}
