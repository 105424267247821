import {
  Box,
  Flex,
  FlexProps,
  useBreakpointValue,
  useRadio,
  useRadioGroup,
  UseRadioProps,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { ButtonGroup, ButtonGroupProps } from "./ButtonGroup";

type OptionPair = [string, boolean?];

export type RadioGroupProps = ButtonGroupProps & {
  options: OptionPair[];
  value: number;
  extraPadding?: FlexProps["padding"];
  marginRight?: number | string;
};

export function GroupedRadios({
  options,
  value,
  extraPadding,
  marginRight,
  ...rest
}: RadioGroupProps) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "Multiplikator",
    defaultValue: options[0][0],
  });
  const showOptional = useBreakpointValue({ base: false, sm: true });

  let group = getRootProps();
  // Avoid "Warning: Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()?"
  delete group.ref;

  return (
    <ButtonGroup {...group} {...rest}>
      {options
        .filter((v) => showOptional || (v[1] ?? true))
        .map((v) => {
          const radio = getRadioProps({ value: v[0] });
          return (
            <RadioCard
              key={v}
              extraPadding={extraPadding ?? 2}
              isChecked={v[0] === `${value}x`}
              fontWeight={v[1] ?? true ? 600 : 300}
              marginRight={marginRight}
              {...(radio as any)}
            >
              {v}
            </RadioCard>
          );
        })}
    </ButtonGroup>
  );
}

export function RadioCard({
  children,
  fontWeight,
  extraPadding,
  marginRight,
  ...rest
}: UseRadioProps & {
  children: ReactNode;
  extraPadding: FlexProps["padding"];
  fontWeight?: number;
  marginRight?: number | string;
}) {
  const { getInputProps, getCheckboxProps } = useRadio(rest);

  const input = getInputProps();
  const checkbox = getCheckboxProps();
  return (
    <Box as="label">
      <input {...input} />
      <Flex
        height="100%"
        {...checkbox}
        cursor="pointer"
        backgroundColor="black"
        paddingX={extraPadding}
        marginRight={marginRight}
        fontWeight={fontWeight}
        _checked={{
          backgroundColor: "brand.400",
          color: "black",
        }}
        _focus={{
          color: "white",
        }}
        alignItems="center"
      >
        <Box paddingTop="3px">{children}</Box>
      </Flex>
    </Box>
  );
}
