import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

const theme = extendTheme(
  {
    styles: {
      global: {
        body: { bg: "black", fontSize: "3xl", color: "brand.400" },
        p: { color: "#222" },
      },
    },
    components: {
      ModalHeader: {
        // No effect on text in modal header
        baseStyle: {
          fontSize: "4xl",
        },
        sizes: {
          sm: { fontSize: "4xl" },
          md: { fontSize: "4xl" },
          lg: { fontSize: "4xl" },
          xl: { fontSize: "4xl" },
        },
      },
      Text: {
        baseStyle: {
          fontSize: "xl",
          color: "#222",
          lineHeight: "130%",
          paddingBottom: "0.5em",
        },
      },
      Table: {
        baseStyle: {
          td: {
            fontWeight: 500,
            backgroundColor: "white",
          },
        },
        sizes: {
          sm: {
            th: { px: 0, pr: 1 },
            td: { px: 0, pr: 1 },
          },
        },
      },
      Button: {
        baseStyle: {
          bg: "black",
          backgroundColor: "black",
          fontSize: "xl",
          _hover: {
            backgroundColor: "brand.200",
          },
        },
        sizes: {
          sm: { fontSize: "xl", bg: "black" },
          md: { fontSize: "3xl", bg: "black" },
          lg: { fontSize: "3xl", bg: "black" },
          xl: { fontSize: "3xl", bg: "black" },
        },
        variants: {
          horizontal: {},
          vertical: { paddingX: "2", paddingTop: "8", paddingBottom: "6" },
          verticalSmall: { paddingX: "2", paddingTop: "4", paddingBottom: "2" },
        },
      },
    },
    colors: {
      brand: {
        900: "#ffe1cc",
        800: "#ffc499",
        700: "#ffa666",
        600: "#ff8933",
        500: "#ff6c00",
        400: "#cb5600",
        300: "#9a4000",
        200: "#662a00",
        100: "#331500",
        50: "#1a0b00",
      },
    },
  }
  //withDefaultColorScheme({ colorScheme: "brand" })
);

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
